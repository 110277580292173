<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">数据统计</span>
		</div>
		<div class="mt-2 mb-2 p-3">
			<div class="row clearfix">
				<div class="col-md-3 column mb-2">
					<el-card shadow="always">
						<div slot="header" class="clearfix">
							<span>访问量</span>
							<el-tag style="float: right;" type="success">访问</el-tag>
						</div>
						<div class="middle">
							<div class="num1-box">
								{{cardData.visit_count}}
							</div>
						</div>
				
						<div class="bottom clearfix">
							<div class="d-flex align-items-center justify-content-between">
								<div class="left">今日访问量</div>
								<div class="right">{{cardData.today_visit_count}}</div>
							</div>
						</div>
					</el-card>
				</div>
				<div class="col-md-3 column mb-2">
					<el-card shadow="always">
						<div slot="header" class="clearfix">
							<span>总用户</span>
							<el-tag style="float: right;" type="success">用户</el-tag>
						</div>
				
						<div class="middle">
							<div class="num1-box">
								{{cardData.user_count}}
							</div>
						</div>
				
						<div class="bottom clearfix">
							<div class="d-flex align-items-center justify-content-between">
								<div class="left">今日用户</div>
								<div class="right">{{cardData.today_user_count}}</div>
							</div>
						</div>
					</el-card>
				</div>
				<div class="col-md-3 column mb-2">
					<el-card shadow="always">
						<div slot="header" class="clearfix">
							<span>用户福卡总数</span>
							<el-tag style="float: right;" type="success">福卡/抽奖</el-tag>
						</div>
				
						<div class="middle">
							<div class="num1-box">
								{{cardData.user_focas_count}}
							</div>
						</div>
				
						<div class="bottom clearfix">
							<div class="d-flex align-items-center justify-content-between">
								<div class="left">抽奖次数</div>
								<div class="right">{{cardData.user_prize_count}}</div>
							</div>
						</div>
					</el-card>
				</div>
				<div class="col-md-3 column mb-2">
					<el-card shadow="always">
						<div slot="header" class="clearfix">
							<span>中将次数</span>
							<el-tag style="float: right;" type="success">订单</el-tag>
						</div>
						<div class="middle">
							<div class="num1-box">
								{{cardData.prize_record_count}}
							</div>
						</div>
						<div class="bottom clearfix">
							<div class="d-flex align-items-center justify-content-between">
								<div class="left">海报扫码数</div>
								<div class="right">{{cardData.poster_count}}</div>
							</div>
						</div>
					</el-card>
				</div>
			</div>
		</div>
		
		<div class="bg-white m-2 p-2">
			<div class="row clearfix">
				<div class="col-md-3 column mb-2">
					<div class="row clearfix">
						<div class="col-md-6 column mb-2" @click="navigation('user/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(105, 192, 255);" class="el-icon-user m-2"></i>
									<span class="h8">用户管理</span>
								</div>
							</el-card>
						</div>
		
						<div class="col-md-6 column mb-2" @click="navigation('scenic/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(149, 222, 100);"
										class="el-icon-location-outline m-2"></i>
									<span class="h8">景点管理</span>
								</div>
							</el-card>
						</div>
					</div>
				</div>
		
				<div class="col-md-3 column mb-2">
					<div class="row clearfix">
		
						<div class="col-md-6 column mb-2" @click="navigation('focas/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(179, 127, 235);"
										class="el-icon-present m-2"></i>
									<span class="h8">福卡管理</span>
								</div>
							</el-card>
						</div>
						
						<div class="col-md-6 column mb-2" @click="navigation('focas/record')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(255, 156, 110);"
										class="el-icon-present m-2"></i>
									<span class="h8">福卡领取</span>
								</div>
							</el-card>
						</div>
					</div>
				</div>
		
				<div class="col-md-3 column mb-2">
					<div class="row clearfix">
						<div class="col-md-6 column mb-2" @click="navigation('content.material/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(255, 214, 102);"
										class="el-icon-document m-2"></i>
									<span class="h8">内容管理</span>
								</div>
							</el-card>
						</div>
		
						<div class="col-md-6 column mb-2" @click="navigation('prize/index')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(92, 219, 211);"
										class="el-icon-present m-2"></i>
									<span class="h8">奖品管理</span>
								</div>
							</el-card>
						</div>
					</div>
				</div>
		
				<div class="col-md-3 column mb-2">
					<div class="row clearfix">
						<div class="col-md-6 column mb-2" @click="navigation('wxapp/setting')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(255, 133, 192);" class="el-icon-menu m-2"></i>
									<span class="h8">小程序</span>
								</div>
							</el-card>
						</div>
		
						<div class="col-md-6 column mb-2" @click="navigation('setting/setting')">
							<el-card shadow="hover">
								<div class="d-flex justify-content-between align-items-center flex-column">
									<i style="font-size:16px;color: rgb(255, 133, 192);"
										class="el-icon-setting m-2"></i>
									<span class="h8">设置</span>
								</div>
							</el-card>
						</div>
					</div>
				</div>
			</div>
		</div>
		

		<div class="bg-white m-2 p-2">
			<div class="row clearfix">
				<div class="col-md-12 column mb-2">
					<!-- 折线图 -->
					<el-card shadow="always">
						<div id="line" style="width: auto;height:450px;"></div>
					</el-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				cardData: {
					visit_count: 0,
					today_visit_count: 0,
					user_count: 0,
					today_user_count: 0,
					user_focas_count: 0,
					user_prize_count: 0,
					prize_record_count: 0,
					poster_count: 0,
				},
				widgetEcharts: {
					lately7days: [],
				}
			}
		},
		mounted() {
			this.getCardData()
			this.initCharts()
		},
		methods: {
			getCardData() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/index/cardData'
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.cardData = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			navigation(name) {
				// 跳转后台首页
				this.$router.push({
					name: name
				})
			},
			initCharts() {
				// 初始化折线图
				this.initLine()
			},
			
			
			// 初始化折线图
			initLine() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/index/widgetEcharts'
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
			
					// 初始化
					let myChart = echarts.init(document.getElementById('line'));
					// 指定图表的配置项和数据
					let option = {
						title: {
							text: '数据'
						},
						tooltip: {
							trigger: 'axis'
						},
						legend: {
							data: ['抽奖']
						},
						grid: {
							left: '3%',
							right: '3%',
							bottom: '3%',
							containLabel: true
						},
						toolbox: {
							right: '3%',
							feature: {
								saveAsImage: {
									title: '导出视图'
								}, //下载保存为图片,
			
							}
						},
						xAxis: {
							type: 'category',
							boundaryGap: true,
							data: res.data.data.lately7days
						},
						yAxis: {
							type: 'value'
						},
						series: [{
							name: '抽奖',
							type: 'line',
							stack: '抽奖',
							data: res.data.data.userPrizeCountList
						}]
					};
					// 使用刚指定的配置项和数据显示图表。
					myChart.setOption(option);
					setTimeout(() => {
						window.onresize = () => {
							myChart.resize();
						}
					}, 200)
			
					this.widgetEcharts = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style scoped>
	.bg-white>>>.el-card__body {
		padding: 0.625rem 1.25rem
	}

	.middle {
		padding: 0.625rem 0.625rem;
	}

	.middle .num1-box {
		font-size: 25px;
		font-weight: bold;
	}

	.middle .num2-box {
		font-size: 15px;
		display: flex;
		align-items: center;
	}

	.middle .num2-box .value1 {}

	.middle .num2-box .value2 {}

	.bottom {
		padding: 0.5rem 0.625rem;
		font-size: 14px;
		border-top: 0.0625rem solid #EBEEF5;
	}
</style>
